import { Injectable } from '@angular/core';
import { io } from "socket.io-client";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'any'
})
export class SocketioService {

  socket;
  connected = false;

  constructor() { }

  connect(event, data) {
    // Create Connection but not if already connected, to prevent duplicate sockets.
    if (!this.connected) {
      this.socket = io("https://tfmserver.net:3000", { secure: true, reconnection: true, rejectUnauthorized: false, transports: ['websocket'] });
      this.connected = true;
    }


    // LISTEN FOR THESE SOCKET EVENTS FROM SERVER
    this.socket.on("updateLeaderboard", (response) => {
      if (response) {
        // console.log("[SERVER RESPONSE] - Event: updateLeaderboard | Data: " + response);
      }
    });

    // PASS EVENT THRU TO EMIT TO SOCKET SERVER
    this.socket.emit(event, data);

    // END SOCKET CONNECT FUNCTION
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.connected = false;
    }
  }

}
