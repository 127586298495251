import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: 'stats',
    loadChildren: () =>
      import('./pages/stats/stats.module').then((m) => m.StatsPageModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'profile/:address',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'payout',
    loadChildren: () =>
      import('./pages/payout/payout.module').then((m) => m.PayoutPageModule),
  },
  {
    path: 'leaders',
    loadChildren: () =>
      import('./pages/leaders/leaders.module').then((m) => m.LeadersPageModule),
  },
  {
    path: 'guide',
    loadChildren: () =>
      import('./pages/guide/guide.module').then((m) => m.GuidePageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'challenge',
    loadChildren: () =>
      import('./pages/challenge/challenge.module').then((m) => m.ChallengePageModule),
  },
  {
    path: 'exchange',
    loadChildren: () =>
      import('./pages/exchange/exchange.module').then(
        (m) => m.ExchangePageModule
      ),
  },
  {
    path: 'tournament',
    loadChildren: () =>
      import('./pages/tournament/tournament.module').then(
        (m) => m.TournamentPageModule
      ),
  },
  {
    path: 'browser',
    loadChildren: () => import('./pages/browser/browser.module').then( m => m.BrowserPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
