import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './data.service';


import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/evm-utils";


@Injectable({
  providedIn: 'root'
})
export class UserService {


  MM_ADDRESS = "0xF6a09deadF5A10aA7822d95e3228b2315De8f6fa";

  Moralis_APIKEY = 'ivBXwTwkpy70ltxZMEUxVZDI1hOEIWJvFkuOEbkNlyNvE6RUILM5Xmsl92lqiKfD';

  tracking_list = [];
  username = '';
  address= '';
  address_display= '';
  net_worth= '';
  rank = 0;

  constructor(
    private ds: DataService,
    private router: Router
  ) {
    this.ds.getStorage("tracking_list").then((data:any)=>this.tracking_list = data ? data : []);

    this.init();
  }


  async init() {
    Moralis.start({
      apiKey: this.Moralis_APIKEY
    })

    // let user = Moralis.User.current();
    // if (!user) {
    //   Moralis.authenticate({
    //     signingMessage: "Log in using Moralis",
    //   }).then(function (user) {
    //     console.log("MJoralis logged in user:", user);
    //     console.log(user.get("ethAddress"));
    //   }).catch(function (error) {
    //     console.log(error);
    //   });
    // }
  }

  // async getNFTs(address) {
  //   if(!address) return false;
  //   const chain = EvmChain.ETHEREUM;
  //   return Moralis.Web3API.account.getNFTsForContract({ chain, address: address, token_address: "0x56214525566c9f8dcbbd17a108690162476426fa" }).then(({result})=>{
  //     return result
  //   });
  // }

  async getBalance(address, token_address=this.MM_ADDRESS):Promise<any> {
    if(!address) return false;
    const options = { chain: EvmChain.POLYGON, address: address };
    return Moralis.EvmApi.token.getWalletTokenBalances(options).then(({result})=>{
      for(let res of result) {
        if(res.token.contractAddress.equals(token_address)) {
          let balance = Math.round(parseFloat(res.value) * 100) / 100;
          return balance;
        }
      }
      return 0;
    });
  }


  save() {
    this.ds.setStorage("user", {
      username: this.username,
      address: this.address,
      address_display: this.address_display,
      net_worth: this.net_worth,
      rank: this.rank,
    })
  }

  viewProfile(address="", location="") {
    if(!address) address = this.address;
    if(!location) this.router.navigate(["/profile/"+address], { state:{} });
    if(location == "explorer") window.open("https://explorer.mintopoly.io/player/"+address);
    if(location == "website") window.open("https://www.mintopoly.io/profile/"+address);
  }

  isTracking(address) {
    if(!this.tracking_list) return false;
    return this.tracking_list.includes(address)
  }

  isTrackingInList(list) {
    for(let row of list) {
      if(row.constructor === Array) {
        for(let player of row) {
          if(this.isTracking(player.address)) return true;
        }
      }
      else if(this.isTracking(row.address)) return true;
    }
    return false;
  }

  trackPlayer(address) {
    if(!this.tracking_list.includes(address)) {
      this.tracking_list.push(address);
    }
    this.ds.setStorage("tracking_list", this.tracking_list);
  }

  untrackPlayer(address) {
    if(this.tracking_list.includes(address)) {
      this.tracking_list.splice(this.tracking_list.indexOf(address), 1);
    }
    this.ds.setStorage("tracking_list", this.tracking_list);
  }

  displayAddress(address) {
    return address.substring(0, 6)+"..."+address.substring(address.length - 6)
  }

  displayUsername(username) {
    if(window.innerWidth < 300 && username.length > 14) username = username.substr(0, 14) + " \u2026";
    // if(window.innerWidth > 450 && window.innerWidth < 750 && username.length > 14) username = username.substr(0, 14) + " \u2026";
    if(username.length > 22) username = username.substr(0, 18) + " \u2026";
    return username;
  }

  toggleTrackPlayer(address) {
    if(this.tracking_list.includes(address)) {
      this.tracking_list.splice(this.tracking_list.indexOf(address), 1);
    }
    else {
      this.tracking_list.push(address);
    }
    this.ds.setStorage("tracking_list", this.tracking_list);
  }
}
