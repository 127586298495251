import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-qrcode-popup',
  templateUrl: './qrcode-popup.component.html',
  styleUrls: ['./qrcode-popup.component.scss'],
})
export class QRCodePopupComponent implements OnInit {

  address = "";

  constructor(
    private modalCtr: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.address = this.navParams.data.address;
  }

  async close() {
    await this.modalCtr.dismiss();
  }
}
