import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-leaderboard-filter',
  templateUrl: './leaderboard-filter.component.html',
  styleUrls: ['./leaderboard-filter.component.scss'],
})
export class LeaderboardFilterComponent implements OnInit {

  @Input() filter = {
    sort: "net_worth",
    columns: {
      current_fork: true,
      potential_fork: true,
      combined_fork: false,
      fork_count: false,
      fork_on_block: false,
      cards: true,
    }
  }
  
  column_list = [
    "current_fork",
    "potential_fork",
    "combined_fork",
    "fork_count",
    "fork_on_block",
    "cards",
  ]

  constructor(
    private modalCtr: ModalController
  ) { }

  async ngOnInit() {
  }

  displayColName(col) {
    return col.replace(/_/g," ")
  }

  async close() {
    await this.modalCtr.dismiss({
      filter: this.filter
    });
  }

}
