import { Component, QueryList, ViewChildren } from '@angular/core';
import { EventsService } from './services/events.service';
import {
  AlertController,
  IonItemSliding,
  ModalController,
  Platform,
  ToastController,
  ToastOptions,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './services/data.service';
import { MetamaskService } from './services/metamask.service';
import { QRCodePopupComponent } from './components/qrcode-popup/qrcode-popup.component';
import {
  BarcodeScanner,
  BarcodeScannerOptions,
} from '@ionic-native/barcode-scanner/ngx';
import { SocketioService } from './services/socketio.service';
import { DateTime } from 'luxon';
import { UserService } from './services/user.service';
import { utils } from 'ethers';
import {
  Push,
  PushObject,
  PushOptions,
} from '@awesome-cordova-plugins/push/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Router } from '@angular/router';
import { Notification } from './_types/defaults';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  time_remaining = '';
  previousNotification = '';
  notificationCount = 0;

  isNotificationModalOpen = false;
  @ViewChildren(IonItemSliding) slidingItem: QueryList<IonItemSliding>;
  notifications = [];

  isLoading = true;
  showStats = false;

  public block = 0;
  public total_blocks = 0;
  public total_players = 0;
  public start_time = null;
  barcodeScannerOptions: BarcodeScannerOptions;
  barInt: any;
  barCounter = 0;
  time = {
    remaining: '',
    elapsed: '',
  };
  intervals = {
    updateUser: null,
    loadGameData: null,
  };

  showDisclaimer = true;

  constructor(
    public ds: DataService,
    public user: UserService,
    private events: EventsService,
    public metamask: MetamaskService,
    private socketService: SocketioService,

    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,

    private push: Push,
    private router: Router,
    private device: Device,
    public platform: Platform,
    private statusBar: StatusBar,
    private scanner: BarcodeScanner,
    private splashScreen: SplashScreen
  ) {
    this.initializeApp();
    this.barcodeScannerOptions = {
      showTorchButton: true,
      showFlipCameraButton: true,
    };
  }

  ngOnDestroy() {
    this.socketService.disconnect();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.ds.get('general/season').then((res: any) => {
        this.ds.season = res.season;

        if (this.ds.season)
          this.favIcon.href = 'assets/favicon-' + this.ds.season + '.ico';
      });

      if (this.platform.is('cordova')) {
        this.statusBar.styleBlackOpaque();
        this.splashScreen.hide();

        this.ds
          .post('user/getUnviewedNotificationCount', { uuid: this.device.uuid })
          .then((data: any) => {
            this.notificationCount = data.unread_count;
          });

        // check if we have permission to receive push notifications
        this.push.hasPermission().then((res: any) => {
          if (res.isEnabled)
            console.log('We have permission to send push notifications');
          else
            console.log('We do not have permission to send push notifications');
        });

        if (this.platform.is('android')) {
          this.push.createChannel({
            id: 'mnn_events',
            description: 'Mintopoly News Network Event notifications',
            importance: 3, // The importance property ranges from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
            badge: true,
            vibration: true,
            sound: 'default',
          });
        }

        // to initialize push notifications
        const options: PushOptions = {
          android: {
            icon: 'icon',
            iconColor: 'lightblue',
            // clearBadge: false
          },
          ios: {
            alert: true,
            badge: true,
            sound: true,
            clearBadge: false,
          },
        };

        const pushObject: PushObject = this.push.init(options);

        let deviceInfo = {
          uuid: this.device.uuid,
          address: this.user.address,
          manufacturer: this.device.manufacturer,
          model: this.device.model,
          version: this.device.version,
          os: this.device.platform.toLowerCase() == 'ios' ? 1 : 0,
          push_token: '',
        };

        pushObject.on('registration').subscribe((registration: any) => {
          console.log('Registered device push notifications', registration);
          this.ds.post('user/updateDeviceInfo', {
            ...deviceInfo,
            push_token: registration.registrationId,
          });
        });

        pushObject.on('notification').subscribe(async (notification: any) => {
          const notId = notification.additionalData.notId;

          if (this.previousNotification != notId) {
            this.previousNotification = notId;
            this.ds.setStorage('previousNotification', notId);

            console.log('notification received', notification);
            //is there a way to track how many are pending?
            this.notificationCount++;

            if (notification.additionalData.coldstart) {
              console.error(
                'coldstart - handle event navigation?',
                notification.additionalData
              );
            } else {
              let info = notification.additionalData.info;
              let toastOptions: ToastOptions = {
                message: notification.title,
                duration: 5000,
                position: 'top',
              };
              if (info.button)
                toastOptions.buttons = [{ text: info.button, role: 'cancel' }];

              let toast = await this.toastCtrl.create(toastOptions);
              toast.present();
              let { role } = await toast.onDidDismiss();

              //toggle notification to viewed if the app was open when it came in
              this.notificationCount = await this.ds
                .post('user/viewedNotification', {
                  uuid: this.device.uuid,
                  push_id: notification.additionalData.info.push_id,
                })
                .then((data: any) => data.unread_count);

              //clicked on the button
              if (role == 'cancel' && info.openPage) {
                if (this.notificationCount > 0) this.notificationCount--;
                this.router.navigate(['/' + info.openPage]);
              }
            }
          }

          // if ((notification.additionalData.coldstart && platform.is('ios')) || (!notification.additionalData.coldstart && notification.additionalData.foreground)) {
          //   console.log('notification received', notification);
          // }
        }),
          (err) => {
            console.error('notification subscription error', err);
          };

        pushObject
          .on('error')
          .subscribe((error) => console.error('Error with Push plugin', error));
      }
    });

    this.ds
      .getStorage('showDisclaimer')
      .then((val: boolean) => (this.showDisclaimer = val));
    this.ds.getStorage('user').then((user: any) => {
      for (let k in user) {
        this.user[k] = user[k];
      }
    });

    //update user data every 15 seconds
    if (!this.intervals.updateUser) {
      this.intervals.updateUser = setInterval(() => {
        if (this.user.address) this.updateUser();
      }, 15000);
    }

    //update game data (mainly for player count) every minute
    if (!this.intervals.loadGameData) {
      this.intervals.loadGameData = setInterval(
        () => this.loadGameData(),
        60000
      );
    }

    //initialize block counter
    this.initializeBlockCounter();

    this.events.getObservable().subscribe((data) => {
      console.log('Event Data received:', data);
      if(data.currentBlock) this.block = data.currentBlock;
      if(data.totalBlocks) this.total_blocks = data.totalBlocks;
      if (data.total_players) this.total_players = data.total_players;
    });

    this.ds
      .getStorage('showStats')
      .then(
        (showStats: boolean) => (this.showStats = showStats ? showStats : false)
      );
    // this.ds
    //   .getStorage('moonToggle')
    //   .then(
    //     (moonToggle: boolean) =>
    //       (this.ds.moonToggle = moonToggle ? moonToggle : false)
    //   );

    this.loadNotifications();
  }

  // toggleMoonMode() {
  //   if (this.ds.moonToggle) {
  //     // CURRENTLY TRUE, CHANGE TO FALSE
  //     this.ds.moonToggle = false;
  //     this.ds.browserDomain = 'https://mintopoly.io/dashboard';
  //     this.ds.setStorage('moonToggle', this.ds.moonToggle);
  //   } else {
  //     // CURRENTLY FALSE, CHANGE TO TRUE
  //     this.ds.moonToggle = true;
  //     this.ds.browserDomain = 'https://moon.mintopoly.io/profile';
  //     this.ds.setStorage('moonToggle', this.ds.moonToggle);
  //   }

  //   // VARIOUS PAGE CHECKS TO NAVIGATE ACCORDINGLY / REFRESH BROWSER
  //   if (this.router.url != '/dashboard' && this.router.url != '/browser' && this.router.url != '/guide')
  //     this.router.navigate(['/dashboard']);
  // }

  toggleDisclaimer() {
    this.showDisclaimer = !this.showDisclaimer;
    this.ds.setStorage('showDisclaimer', this.showDisclaimer);
  }

  async initializeBlockCounter() {
    await this.loadGameData();
    this.countBlock();
    setInterval(() => this.countBlock(), 15000);
  }

  async countBlock() {
    const date1 = DateTime.fromISO(this.start_time);
    const date2 = DateTime.now();
    const diff = date1.diff(date2, ['seconds']);

    //this should be updated - maybe use CSS animations?
    clearInterval(this.barInt);
    this.barCounter = 0;
    this.barInt = setInterval(() => {
      this.barCounter++;
    }, 15);

    this.block = this.ds.current_block = Math.abs(
      Math.floor(diff.toObject().seconds / 15)
    );
  }

  async loadGameData() {
    this.ds.get('leaderboard/getActiveRound').then((data: any) => {
      if (data.round_data) {
        this.ds.round = data.round_data;

        //setting up time remaining logic
        if (!this.ds.round.is_active) {
          const start_time = DateTime.fromISO(
            this.ds.round.next_round.startTime
          );

          let refreshId = setInterval(() => {
            const now = DateTime.local();
            let t = start_time
              .diff(now, ['days', 'hours', 'minutes', 'seconds'])
              .toObject();

            this.time_remaining = '';
            if (t.days) this.time_remaining += t.days + 'd ';
            if (t.hours) this.time_remaining += t.hours + 'h ';
            if (t.minutes) this.time_remaining += t.minutes + 'm ';
            if (t.seconds) this.time_remaining += Math.round(t.seconds) + 's ';

            if (now.valueOf() >= start_time.valueOf()) clearInterval(refreshId);
          }, 1000);
        }
      }
    });

    await this.ds.get('leaderboard/getGameData').then((data: any) => {
      if (data.data.startTime) {
        this.start_time = data.data.startTime;

        //calc days elapsed
        const start_time = DateTime.fromISO(this.start_time);
        const now = DateTime.local();
        const roundend = DateTime.local().plus({
          minutes: (data.data.totalBlocks - data.data.currentBlock) / 4,
        });
        const elapsed = now.diff(start_time, ['days', 'hours']).toObject();
        const remaining = roundend.diff(now, ['days', 'hours']).toObject();

        this.time.remaining = `${remaining.days}d, ${
          Math.round(remaining.hours * 10) / 10
        }h`;
        this.time.elapsed = `${elapsed.days}d, ${
          Math.round(elapsed.hours * 10) / 10
        }h`;
      }
      if (data.total_blocks) this.total_blocks = data.total_blocks;
      if (data.total_players) this.total_players = data.total_players;
    });
  }

  toggleStats() {
    this.showStats = !this.showStats;

    this.ds.setStorage('showStats', this.showStats);
  }

  async openQR() {
    if (!this.user.address) return;
    const modal = await this.modalCtrl.create({
      component: QRCodePopupComponent,
      componentProps: {
        address: this.user.address,
      },
    });
    return await modal.present();
  }
  scanQRCode() {
    this.scanner
      .scan()
      .then((res: any) => {
        this.login(res.text);
      })
      .catch((err) => {
        alert(err);
      });
  }

  async metamaskLogin() {
    let address = await this.metamask.login();
    address = utils.getAddress(address);
    await this.login(address);
    window.location.reload;
  }

  async login(address) {
    await this.updateUser(address);
    this.ds.presentToast(
      'Welcome to your personalized Mintopoly! Stats experience, ' +
        this.user.username +
        '!'
    );
    this.user.save();
  }

  async updateUser(address = '') {
    if (!address && this.user.address) address = this.user.address;

    if (address) {
      let address_display =
        address.substring(0, 6) + '...' + address.substring(address.length - 6);
      let userData: any = await this.ds
        .post('leaderboard/getUserData', { address })
        .then((data: any) => data.userData);

      this.user.address = address;
      this.user.address_display = address_display;

      for (let k in userData) {
        this.user[k] = userData[k];
      }
      this.user.save();
    } else {
      console.log('No User Set');
    }
  }

  clearUserData() {
    this.user.address = '';
    this.user.username = '';
    this.user.save();
    this.ds.setStorage("user", null);
  }

  async toggleNotificationModal(state) {
    this.isNotificationModalOpen = state;
    if (state) {
      await this.loadNotifications();
    }
  }

  async loadNotifications(event: any = null) {
    if (event === null) this.isLoading = true;
    await this.ds
      .post('user/loadNotifications', {
        uuid: this.device.uuid,
        address: this.user.address,
      })
      .then((data: any) => {
        this.notifications = data.notifications;

        this.isLoading = false;
        if (event) event.target.complete();
      });
  }

  readNotification(notification: Notification) {
    this.ds.post('user/viewedNotification', {
      uuid: this.device.uuid,
      address: this.user.address,
      push_id: notification.push_id,
    });
    notification.viewed = true;
    this.slidingItem.forEach((el) => el.close());
  }

  async deleteReadNotifications() {
    const alert = await this.alertCtrl.create({
      header: 'Clear Notifications',
      message: 'Are you sure you want to delete your notifications?',
      mode: 'ios',
      buttons: [
        {
          text: 'Delete',
          role: 'confirm',
          handler: () => {
            let unViewedNots = this.notifications.filter((el) => el.viewed);

            //delete all read notifications if they are the only ones left
            if (unViewedNots.length == this.notifications.length) {
              this.ds.post('user/deleteAllNotifications', {
                uuid: this.device.uuid,
              });
            }
            //delete all read notifications
            else {
              this.ds.post('user/deleteReadNotifications', {
                uuid: this.device.uuid,
              });
            }

            console.log('post');
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
  }
}
