import { Injectable } from '@angular/core';
import { DataService } from './data.service';

declare global {
  interface Window { ethereum: any; }
}

@Injectable({
  providedIn: 'root'
})
export class MetamaskService {
  userAddress = null
  exists = window.ethereum ? true : false;

  constructor(
    private data: DataService
  ) { }

  async login() {
    const walletAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' }).catch((e) => {
      // META AUTH SIGNING CANCELLED OR FAILED - DO SOMETHING?
      this.data.presentToast(e.message, "danger")
      return;
    });

    this.userAddress = walletAccounts[0];
    return this.userAddress;
  }
  
}
