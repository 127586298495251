import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';

import { QRCodePopupComponent } from './qrcode-popup/qrcode-popup.component';
import { LeaderboardFilterComponent } from './leaderboard-filter/leaderboard-filter.component';


@NgModule({
  declarations: [
    QRCodePopupComponent,
    LeaderboardFilterComponent,
  ],
  imports: [
    IonicModule,
    FormsModule,
    QRCodeModule,
    CommonModule,
  ],
  exports: [
    QRCodePopupComponent,
    LeaderboardFilterComponent,
  ]
})
export class ComponentsModule { }