import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@ionic-native/clipboard/ngx';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  link: string = 'https://mintoapi.techfullymade.com/v1/';
  debug = false;
  mobile = false;
  app_version = '1.0.11';


  round_list:any = [];
  tourney_list:any = [];
  round:any = {
    is_active: false,
    number: 0,
    last_update: '',
    investments: [],
    next_round: {
      startTime: '',
    }
  }
  current_block = 0;

  leaderboardData = {
    loaded: false,
    current_page: 1,
    page_list: []
  }

  season = "";

  moonToggle;
  browserDomain = '';
  netChangeToggle = "net_change";

  constructor(
    private http: HttpClient,
    public storage: Storage,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private clipboard: Clipboard,
    private platform: Platform,
  ) {
    if(this.round_list.length == 0) {
      this.loadRoundList();
    }

    this.storage.create().then(()=>{
      //put any storage requests here
    });

    if(window.innerWidth < 750) this.mobile = true;
    this.debug = !environment.production;
  }

  async ngOnInit() {
  }


  public async loadRoundList() {
    return this.get("leaderboard/loadRoundList").then(async(data:any)=>{
      this.round_list = data.round_list;
    });
  }

  public async setStorage(settingName, value, showLog=true){
    if(this.debug && showLog) console.log('setStorage - '+settingName, value);
    if(typeof value === 'object') value = JSON.stringify(value);
    return this.storage.set(`${ settingName }`, value);
  }

  public async getStorage(settingName, showLog=true){
    return new Promise((res, rej) => {
      this.storage.get(`${ settingName }`).then((data)=>{
        try {
            let parsed = JSON.parse(data);
            if (parsed && typeof parsed === "object") {
                if(this.debug && showLog) console.log('getStorage - '+settingName, parsed);
                return res(parsed);
            }
            else res(data);
        }
        catch (e) { res(data) }
      });
    })
  }
  public async removeStorage(settingName, showLog=true){
    if(this.debug && showLog) console.log('removeStorage - '+settingName)
    return await this.storage.remove(`${ settingName }`);
  }
  public async clearStorage(showLog=true) {
    if(this.debug && showLog) console.warn('clearStorage')
    return await this.storage.clear();
  }


  getHeaders() {
    return new Promise((resolve, reject) => {
      return this.getStorage("X-Auth-Token")
        .then((token:string)=>resolve({
          headers: new HttpHeaders ({
            'X-Auth-Token': token ? token : '',
            'X-App-Version': this.app_version.replace(/\./g,''),
            'Content-Type': 'application/json',
          })
        }))
        .catch(()=>resolve({
          headers: new HttpHeaders ({
            'X-Auth-Token': '',
            'Content-Type': 'application/json',
          })
        }))
    })
  }



  async presentToast(message='', color=undefined) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'top',
      duration: 4000,
      cssClass: "toast-message"+(color ? ' '+color : ""),
    });
    toast.present();
  }

  async alert(message, header="Error") {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: "Okay",
          role: 'cancel',
          cssClass: 'secondary'
        },
      ]
    });
    await alert.present();
  }


  number_format(number, decimals, dec_point, thousands_point) {
    if (number == null || !isFinite(number))
        throw new TypeError("number is not valid");

    if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
    }

    if (!dec_point)
        dec_point = '.';

    if (!thousands_point)
        thousands_point = ',';

    number = parseFloat(number).toFixed(decimals);
    number = number.replace(".", dec_point);

    var splitNum = number.split(dec_point);
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
    number = splitNum.join(dec_point);
    return number;
}

copyToClipboard(content) {
  console.log('copying content', content, content.length)
  if(this.platform.is('cordova')) this.clipboard.copy(content);
  navigator.clipboard.writeText(content)
  if(content.length < 50) this.presentToast("Copied "+content+" to clipboard!")
  else this.presentToast("Copied data to clipboard!")
}




  /* -----Data Formatting----- */
  convertRankChange(rank_change) {
    if(rank_change == 0) return "";
    return Math.abs(rank_change);
  }
  convertNet(net_worth) {
    net_worth = parseFloat(net_worth)
    let net = ""
    if(net_worth < 1) net = net_worth.toFixed(10)
    if(net_worth < 10) net = net_worth.toFixed(9)
    if(net_worth >= 10) net = this.number_format(net_worth, 8, '.', ',');
    if(net_worth >= 100) net = this.number_format(net_worth, 4, '.', ',');
    if(net_worth >= 1000) net = this.number_format(net_worth, 2, '.', ',');

    return net;
  }

  convertNetChange(net_worth, net_change) {
    net_worth = parseFloat(net_worth)
    let change = ""
    if(net_worth < 1) change = net_change.toFixed(10)
    if(net_worth < 10) change = net_change.toFixed(6)
    if(net_worth >= 10) change = this.number_format(net_change, 4, '.', ',');
    if(net_worth >= 100) change = this.number_format(net_change, 2, '.', ',');
    if(net_worth >= 1000) change = this.number_format(net_change, 1, '.', ',');

    return change;
  }

  toggleNetChange(changeType){
    if(changeType == "net_change") this.netChangeToggle = "change_percent";
    else this.netChangeToggle = "net_change";

    this.setStorage("netChangeToggle", this.netChangeToggle);
    return this.netChangeToggle;
  }

  get(endpoint, api="mintostats") {
    return new Promise((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
      let url = this.link;
      if(api === "mintopoly") url = "https://api.mintopoly.io/"

      this.http.get(url + endpoint, httpOptions).subscribe((data) => {
        console.log(endpoint, data);
        resolve(data);
      },
      (err:HttpErrorResponse) => {
        console.error('get', err);
        reject(err);
      });
    });
  }

  post(endpoint, data, api="mintostats") {
    return new Promise((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
      this.http.post(this.link + endpoint, JSON.stringify(data), httpOptions).subscribe(data => {
        console.log(endpoint, data);
        resolve(data);
      },
      (err: HttpErrorResponse) => {
        console.error('post', err)
        reject(err)
      });
    });
  }


}
